body {
  font-size: 1rem;
}

#error,
#culprits-error {
  display: none;
  padding: 100px;
  color: red;
}

td a {
  padding-right: 5px;
}

#cloak {
  padding: 100px 0;
  height: 100%;
  width: 100%;
}

#cloak p {
  text-align: center;
}

a.resolved {
  text-decoration: line-through !important;
}

.page-header.culprits {
  margin-top: 60px;
}

.culprits h4 {
  margin-top: 35px;
}

input.form-control.name {
  margin-top: 10px;
}

a {
  color: rgb(51, 122, 183);
}

td.checked {
  background: url(./static/check.png) no-repeat;
  background-position: center;
}

.media-body h5 {
  margin-bottom: 0;
}

.user-avatar-group {
  vertical-align: middle;
  width: 44px;
  height: 44px;
  margin-right: 6px;
  padding: 0;
  position: relative;
  display: inline-block;
}

.user-avatar:first-child {
  width: 44px;
  height: 44px;
}

.user-avatar:not(:first-child) {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 0;
  right: -6px;
}

.user-avatar > img {
  border-radius: 3px; /* this is what GitHub uses */
  width: inherit;
  height: inherit;
}

.user-avatar.unknown-user {
  display: inline-block;
  /* border: 1px solid black; */
  background: #ccd;
  border-radius: 3px; /* this is what GitHub uses */
}

.user-avatar.unknown-user::after {
  content: '?';
  line-height: 44px;
  display: block;
  text-align: center;
  font-size: 1.5em;
}

#whatisit {
  margin-top: 150px;
}

#previous {
  margin-top: 50px;
}

#previous li .names {
  margin-left: 10px;
  font-size: 80%;
  color: #666;
}

a.compare-url {
  font-size: 80%;
}

.commit-date {
  font-weight: normal;
  color: #666;
}

footer {
  margin-top: 60px;
}
footer p {
  text-align: center;
}

.deployed-metadata {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.deployed-metadata > div {
  flex: 1 0 50%;
}

.deployed-metadata > div.metadata-actions {
  text-align: right;
}

th > .column-extra {
  float: right;
  font-weight: normal;
  margin-right: 20px;
}

h2 .reponame {
  color: #666;
}
